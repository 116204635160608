/*------------- folder box----------- */
.folder {
    width: 150px;
    height: 105px;
    margin: 50px 50px;
    float: left;
    position: relative;
    background: rgb(110,166,239);
    background: linear-gradient(183deg, rgba(110,166,239,1) 11%, rgba(30,92,172,1) 100%);
    border-radius: 0 6px 6px 6px;
    transition: 0.10s ease-out;
    /* box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.59); */
}
/*-------------folder box----x------- */
.folder:hover{
  transform: scale(1.2);
}
/*-------------file opener----------- */
.folder:before {
    content: '';
    width: 46%;
    height: 21px;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    border-radius: 4px 0px 0 0;
    background-color: #1e5cac;
    position: absolute;
    top: -10.3px;
    left: 0px;
}
/*-------------file opener----x------- */

/*------------- Document ----------- */
.folder:after {
    content: '';
    width: 29%;
    height: 5px;
    border-radius: 2px 2px 0 0;
    background-color: #ffffff;
    position: absolute;
    top: 5.6px;
    left: 4px;
}
/*-------------Document ----x------- */

/*------------- text  ----------- */
.p-tag{
  position: absolute;
  left: 10px;
  top: 75px;
  margin: 0%;
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  color: white;
 
}


.folder-card-wrap {
    display: flex;
  }
  
  .folder-card {
    background-color: #f4f8fb;
    padding: 3rem 3rem 2rem;
    z-index: 1;
  }
  .folder-card:hover, .folder-card:focus {
    background-color: #e1ecf4;
    cursor: pointer;
  }
  .folder-card:active {
    background-color: #bcd4e7;
  }
  .folder-card + .folder-card {
    margin-left: 1rem;
  }
  .folder-card__icon {
    background-color: #74a3ff;
    border-radius: 0 10px 10px 10px;
    width: 63px;
    height: 42px;
  }
  .folder-card__icon:before {
    background: linear-gradient(45deg, #508bff 45%, rgba(255, 255, 255, 0) 45%);
    border-top-left-radius: 7px;
    content: "";
    display: block;
    width: 100%;
    height: 0.625rem;
    top: -0.625rem;
    z-index: -1;
  }
  .folder-card__title {
    display: block;
    text-align: center;
    margin-top: 1.25rem;
  }
  .folder-card.notification:after {
    width: 0.625rem;
    height: 0.625rem;
    content: "";
    display: inline-block;
    background-color: red;
    border: 6px solid white;
    border-radius: 50%;
    right: -5px;
    top: -5px;
  }

  .task-card__option--attachment:after, .task-card__option--due:after, .task-card__option, .task-card__checkmark, .folder-card.notification:after, .folder-card__icon:before, .search-bar-icn, .main-nav__link:before, .container {
    position: absolute;
  }
  
  .task-card, .folder-card__icon, .folder-card, .search-wrap, .main-nav__link {
    position: relative;
  }

  .delete-folder{
    position: absolute;
    top: 0;
    right:0;
  }

  @media (max-width: 768px) {
    .folder-card-wrap {
      display: flex;
      margin: 0 auto;
      padding: 0;
      flex-wrap: wrap;
    }
  
    .folder-card {
      width: 20%; /* Make folder cards take up almost the entire width on mobile */
      margin: 10px auto; /* Center the cards */
      padding: 1.5rem 1rem; /* Adjust padding for smaller screens */
    }
  
    .folder-card__icon {
      width: 50px;
      height: 35px;
    }
  
    .folder-card__title {
      font-size: 0.875rem; /* Slightly smaller title text for mobile */
    }
  
    .delete-folder {
      top: 5px;
      right: 5px;
    }
  
    /* Reduce the hover transformation scale on smaller screens */
    .folder:hover {
      transform: scale(1.05); /* Smaller scale effect on mobile */
    }
  }
  