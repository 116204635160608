/* Set up the background animation */
.auth-uppr {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #1d2b64 0%, #f8cdda 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.auth-container {
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1.5s ease-out;
  text-align: center;
}

.auth-container h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.auth-form input {
  width: 90%;
  padding: 12px;
  margin-bottom: 15px;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
  font-size: 16px;
}

.auth-form input:focus {
  outline: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.auth-button {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 6px;
  background-color: #1d2b64;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.auth-button:hover {
  background-color: #f8cdda;
  color: #1d2b64;
}

.auth-form a {
  display: inline-block;
  margin-top: 15px;
  color: #1d2b64;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.auth-form a:hover {
  color: #f8cdda;
}

/* Error message styles */
.auth-error {
  color: #e74c3c;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

.auth-uppr {
  background: linear-gradient(135deg, #636e98, #f8cdda);
  background-size: 400% 400%;
}
